import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { mediaMax } from '../../utils/breakpoints';
import { Event } from '../../types/events';
import VersionOneEvent from './EventVersions/VersionOne';
import { Title, Abstract } from './components/Typography';
import { graphql, useStaticQuery } from 'gatsby';


export default( event  : any) => {
  const ThisEvent : Event = event.event;

  const data = useStaticQuery(
    graphql`
      query {
        Background: file(relativePath: { eq: "alexander-popov-vCbKwN2IXT4-unsplash.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  
  return (
    <EventWrapper>
      <StyledImage fluid={data.Background.childImageSharp.fluid}/>
      
      <Content>
        <TopContent>
          <StyledDate>{ThisEvent.time}</StyledDate>
          <StyledTitle>{ThisEvent.title}</StyledTitle>
          <Abstract dangerouslySetInnerHTML={{
            __html: ThisEvent.information
          }}></Abstract>

        </TopContent>

        <BottomContent>
          <VersionOneEvent event={ThisEvent}/>
        </BottomContent>
        
      </Content>
    </EventWrapper>
  )

}

const StyledDate = styled.div`
  color:white;
  font-size: max(18px, 1.8vw);
  padding-top:5.4vw;
  padding-bottom:10px;

  @media ${mediaMax.tablet}{
    padding-top:100px;
  }
`

const StyledImage = styled(Img)`
  height:100vh;
  width:100vw;
  position:absolute !important;
  z-index:-1;
`

export const TopContent = styled.div`
  min-height:50vh;
  padding-left:calc(100vw * 0.092);
  //background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 29%, rgba(0,0,0,0.9) 82%);

  @media ${mediaMax.tablet} {
    padding: 80px 15px 0 15px;
    padding-right: 30%;
  }

  @media ${mediaMax.mobileL}{
    padding-right:15px;
  }
`

export const BottomContent = styled.div`
  min-height:40vh;
  padding-top: calc(100vw * 0.0583);
  padding-left:calc(100vw * 0.092);

  @media ${mediaMax.tablet}{
    width: 100vw;
    padding: 20px 15px 80px 15px;
  }
`

const EventWrapper = styled.div`
`

const Content = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: ${({theme}) => theme.fonts.fontFamilyPrimary};
`

const StyledTitle = styled(Title)`
  
`