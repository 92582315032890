import React from "react";

import Layout, { MyLocationEnum } from "../components/Layout";
import styled from "styled-components";

import Event from "../components/Event/Event";

import { mediaMin, mediaMax } from "../utils/breakpoints";
import SEO, { MetaOG } from "../components/Seo";

const event = ({ pageContext, location, navigate, ...rest }) => {
  
  return (
    <Layout myLocation={MyLocationEnum.Speaker} location={location}>
        <SEO title="Viðburður" description="Upplýsingar um viðburð"/>

        <Event event={pageContext.speaker}></Event>
    </Layout>
  );
};

export default event;
