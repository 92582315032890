import React from 'react';
import styled from 'styled-components';
import { Event } from '../../../types/events';
import { mediaMax } from '../../../utils/breakpoints';
import Img from 'gatsby-image';
import { Description } from '../components/Typography';


interface EventProps{
  event : Event
}

const VersionOneEvent = ({ event } : EventProps) => {
  return (
    <VersionOne>
      <Speakers>
        <Speaker>
          {event.image != null && event.image != undefined && <SpeakerImage fluid={event.image.childImageSharp.fluid} alt="speaker mynd"></SpeakerImage>}
          <SpeakerInfo>
            <SpeakerName>{event.speakerName}</SpeakerName>
            <SpeakerJob>{event.companyName}</SpeakerJob>
          </SpeakerInfo>
        </Speaker>
      </Speakers>
    </VersionOne>
  )
}

export default VersionOneEvent;


const VersionOne = styled.div`

`

const SpeakerImage = styled(Img)`
  
  background-color:white;
  object-fit:cover;
  margin-right:25px;
  width:13.3vw;
  height:13.3vw;
  min-width:40px;
  min-height:40px;
`

const SpeakerInfo = styled.div`

`

const SpeakerJob = styled.span`
  display:block;
  color:white;
  font-size:calc(100vw * 0.00972);
  font-family: ${({theme}) => theme.fonts.fontFamilySecondary};
  
  @media ${mediaMax.tablet}{
    font-size:13px;
    line-height:22px;
  }
`

const SpeakerName = styled.span`
  display:block;
  color:white;
  font-size:calc(100vw * 0.0125);
  font-family: ${({theme}) => theme.fonts.fontFamilyPrimary};

  @media ${mediaMax.tablet}{
    font-size:16px;
    line-height:22px;
  }
`

const Speaker = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  margin-bottom:25px;
`

const Speakers = styled.div`
  h2{
    color:white;
    margin-bottom:40px;
    font-size:calc(100vw * 0.021);
    font-family: ${({theme}) => theme.fonts.fontFamilyPrimary};
    margin-top:80px;

    @media ${mediaMax.tablet}{
      font-size:18px;
      line-height:22px;
    }
  }
`